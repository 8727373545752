import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'
import { Document } from '@/types/document/document'

interface DocumentFolderType<TValue> {
  [id: string]: TValue;
}

export const documentStore = defineStore('document', () => {
  const loading = ref(false)
  const activeTab = ref<string|null>(null)
  const documents = ref<DocumentFolderType<{ name:string, documents:Document[] }>|null>(null)

  function getDocuments () : void {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/building/documents').then((r) => {
      documents.value = r.data
      if (documents.value && !Array.isArray(documents.value)) {
        activeTab.value = documents.value[Object.entries(documents.value)[0][0]].name || null
      }
    }).finally(() => {
      loading.value = false
    })
  }

  return { loading, activeTab, documents, getDocuments }
})
